<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="false"
  >
    <mat-toolbar>Menu</mat-toolbar>
    <mat-nav-list>
      <a
        mat-list-item
        routerLink="/"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="{ exact: true }"
        (click)="drawer.close()"
        >Home</a
      >
      <a
        mat-list-item
        routerLink="/login"
        routerLinkActive="active-link"
        (click)="drawer.close()"
        >Login</a
      >
      <a
        mat-list-item
        routerLink="/kanban"
        routerLinkActive="active-link"
        (click)="drawer.close()"
        >Kanban</a
      >
      <a
        mat-list-item
        routerLink="/posts"
        routerLinkActive="active-link"
        (click)="drawer.close()"
        >Posts</a
      >
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar>
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async"
      >
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span class="logo" routerLink="/">🐻 Bear Collaborate</span>
      <span class="fill-space"></span>
      <div class="buttons" *ngIf="!(isHandset$ | async)">
        <a mat-button routerLink="/kanban" routerLinkActive="active-link"
          >🍱 Kanban Demo</a
        >
        <a mat-button routerLink="/posts" routerLinkActive="active-link"
          >ℹ Message Board Demo</a
        >
        <div *ngIf="afAuth.authState | async as user">
          <a
            *ngIf="user.uid === 'xWWyaqL1PLYONwMlf0F5OIFo2e22'"
            mat-button
            routerLink="/team"
            routerLinkActive="active-link"
            >👽 Teammates</a
          >
        </div>
        <a
          *ngIf="!(afAuth.authState | async)"
          mat-button
          routerLink="/login"
          routerLinkActive="active-link"
          >🔑 Login</a
        >
        <div *ngIf="afAuth.authState | async as user">
          <img
            *ngIf="user.photoURL"
            routerLink="/login"
            class="profile-picture"
            referrerPolicy="no-referrer"
            [src]="user.photoURL"
            alt="Profile Picture"
          />

          <img
            *ngIf="!user.photoURL"
            routerLink="/login"
            class="profile-picture"
            referrerPolicy="no-referrer"
            src="/assets/annon.jpg"
            alt="Profile Picture"
          />
        </div>
      </div>
    </mat-toolbar>
    <ng-content> </ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
