<header>
  <div class="left">
    <h1>
      <div class="line">Andre Rivera</div>
      <div class="line">Coder</div>
      <div class="line">And a Legend</div>
    </h1>
  </div>
  <div class="right"></div>
</header>

<div class="segment pink">
  <h1>Idea behind this Website</h1>
  <p>
    I mainly wanted to try out some fun little features I saw over the last few
    weeks with firebase. This Project was created in less than 48h. (over a
    Weekend).
  </p>
</div>

<div class="segment">
  <div class="row">
    <div class="left chess-picture"></div>
    <div class="right">
      <h1>What can I do here?</h1>
      <p>
        On this platform you can Login and manage your Personal Kanban Boards.
        It is suppose to be a collaboration Website but due to the time
        constrains i opted not to make the Kanban Boards shared. (But you can
        drag and drop them!)
      </p>
      <p>
        You can also publish messages on the infinitely scrolling messageboard
        or check out the post from the other Users. If you don't know anybody
        you can check out all Profiles on the "Teammates" Tab. Or even check out
        a single Profile to see what that person posted.
      </p>
    </div>
  </div>
</div>

<div class="segment pink">
  <h1>Link to the Repo</h1>
  <p>If you are interested in the code, check out the Repo.</p>
  <a
    href="https://github.com/Herofresh/RiveraCollaborationPlatform"
    target="blank"
    ><img src="/assets/github.png"
  /></a>

  <h1>For good measure, here are my contact details:</h1>
  <p>
    Email:
    <a href="mailto:andre.rivera.arboleda@gmail.com" target="blank"
      >andre.rivera.arboleda@gmail.com</a
    >
  </p>
  <p>Phone: <a href="tel:+436764748680" target="blank">+43 676 474 8680</a></p>
  <p>CV: <a href="/assets/cv.pdf" target="blank">here</a></p>
</div>
