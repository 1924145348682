// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBjtCvI8NiPQk5A6beduMxIkW_KdneqEyo',
    authDomain: 'messageboard-301910.firebaseapp.com',
    projectId: 'messageboard-301910',
    storageBucket: 'messageboard-301910.appspot.com',
    messagingSenderId: '2453259171',
    appId: '1:2453259171:web:764c41d9ab5edf5dee5016',
    measurementId: 'G-ZGT7DPGWRG',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
